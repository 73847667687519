import React from "react";
import { Nav, Navbar, Container } from "reactstrap";
import Menu from "@simplur/gatsby-theme-full-site/src/components/menu";
import { PhoneNumberNavItem } from "@simplur/gatsby-theme-full-site/src/components/NavItems";

const TopNav = () => {
  return (
    <Navbar
      color="gray-nav"
      className="py-0"
      expand="xl"
      style={{ fontFamily: "DM Sans" }}
    >
      <Container>
        <div className="nav w-100 py-0">
          <Nav className="d-none d-md-block mr-auto">
            <PhoneNumberNavItem />
          </Nav>
          <Menu />
        </div>
      </Container>
    </Navbar>
  );
};

export default TopNav;
