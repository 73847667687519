import React from "react";
import { NavItem } from "reactstrap";
import { Link } from "gatsby";
import Logout from "@simplur/gatsby-theme-full-site/src/components/logout";
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";

const SignInNavItem: React.FC = () => {
  const { localStorageKeys } = useThemeOption();

  let isLoggedIn = null;
  if (typeof window !== "undefined") {
    isLoggedIn = localStorage.getItem(localStorageKeys.authToken);
  }

  return (
    <NavItem>
      {!isLoggedIn ? (
        <Link className="nav-link" to="/login">
          Sign In
        </Link>
      ) : (
        <Logout className="logout__btn" />
      )}
    </NavItem>
  );
};

export default SignInNavItem;
