import React from "react";
import { Link } from "gatsby";
import { FaChevronRight } from "react-icons/fa";

const MenuItem = ({
  name,
  children,
  uri,
  catIndex,
  showSubMenu,
  setTheSubMenu
}) => {
  const hasChildren = children ? children.length : false;

  return (
    <li>
      <Link to={uri} className="menu-link mega-menu-link">
        {name}

        {hasChildren ? (
          <button
            onClick={e => {
              e.preventDefault();
              setTheSubMenu(catIndex);
            }}
            style={{
              color: "white",
              float: "right",
              backgroundColor: "transparent",
              border: "none",
              lineHeight: "100%"
            }}
          >
            {showSubMenu === uri ? <FaChevronRight /> : <FaChevronRight />}
          </button>
        ) : (
          ""
        )}
      </Link>

      {hasChildren ? (
        <ul
          className={`child__menu ${showSubMenu === catIndex ? "active" : ""}`}
        >
          {children.map((item, childIndex) => (
            <MenuItem
              key={item.name}
              {...item}
              catIndex={`child_index_${childIndex}`}
              setTheSubMenu={setTheSubMenu}
              showSubMenu={showSubMenu}
            />
          ))}
        </ul>
      ) : (
        ""
      )}
    </li>
  );
};

export default MenuItem;
