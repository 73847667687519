import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

const SiteLogo = () => {
  const data = useStaticQuery(graphql`
    query SiteLogoQuery {
      wpgraphql {
        themeGeneralSettings {
          ACFLogos {
            logo {
              altText
              sourceUrl
              srcSet
            }
          }
        }
      }
    }
  `);

  const logo = data?.wpgraphql?.themeGeneralSettings?.ACFLogos?.logo;

  return (
    <span className="site__logo">
    <Link to="/">
      <img src={logo?.sourceUrl} alt={logo?.altText} className="site-logo" />
      </Link>
    </span>
  );
};

export default SiteLogo;
