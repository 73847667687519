import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col, Badge } from "reactstrap";
import { Heart as HeartIcon } from "styled-icons/fa-regular/Heart";

import { useWishList } from "@simplur/gatsby-theme-full-site/src/hooks";

import SiteLogo from "../SiteLogo";
import SearchForm from "../searchForm";
import CartItem from "../NavItems/CartNavItem";
import { LeftContainer } from "./styles";

const Header = () => {
  const { userId, wishList } = useWishList([]);

  return (
    <header>
      {/* <div style={{ backgroundColor: "#eee" }}> */}
      <Container>
        <Row className="d-flex align-items-center py-3">
          <Col md={3} sm={12}>
            <LeftContainer className="mb-2 mb-md-0">
              <SiteLogo />
              <span className="list-unstyled m-0 text-right d-md-none">
                <Link to={userId ? "/wishlist" : "/login"} className="mr-3">
                  <HeartIcon
                    size="1.5rem"
                    color="#1f1f1f"
                    className="align-middle"
                  />
                  {wishList.length ? (
                    <Badge
                      color="primary"
                      className="align-top"
                      style={{ marginLeft: "-..25rem", borderRadius: "50%" }}
                    >
                      {wishList.length}
                    </Badge>
                  ) : null}
                </Link>
                <CartItem />
              </span>
            </LeftContainer>
          </Col>
          <Col md={6} sm={12}>
            <SearchForm />
          </Col>
          <Col md="2" className="ml-auto">
            <ul className="list-unstyled m-0 d-none d-md-block float-right">
              <Link to={userId ? "/wishlist" : "/login"} className="mr-3">
                <HeartIcon
                  size="1.5rem"
                  color="#1f1f1f"
                  className="align-middle"
                />
                {wishList.length ? (
                  <Badge
                    color="primary"
                    className="align-top"
                    style={{ marginLeft: "-..25rem", borderRadius: "50%" }}
                  >
                    {wishList.length}
                  </Badge>
                ) : null}
              </Link>
              <CartItem />
            </ul>
          </Col>
        </Row>
      </Container>
      {/* </div> */}
    </header>
  );
};

export default Header;
