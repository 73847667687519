import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { FooterHeader } from "./styles";

const FooterTopLink = ({ title, links }) => {
  return (
    <div className="footer__top-links text-left">
      {title && (
        <FooterHeader className="footer__widget-title">{title}</FooterHeader>
      )}
      <ul className="list-unstyled text-left footer__links">
        {links.length &&
          links.map((item, index) => (
            <li key={`${item.slug}-${index}`}>
              <Link to={item.slug}>
                {item.label}
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

FooterTopLink.propTypes = {
  title: PropTypes.string,
  links: PropTypes.array
};

FooterTopLink.defaultProps = {
  title: "",
  links: []
};

export default FooterTopLink;
