import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "reactstrap";
import FooterTop from "./SiteFooter/FooterTop";
import FooterSocialLinks from "./SiteFooter/FooterSocialLinks";
import Subscribe from "../components/Subscribe"

import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";

import "./SiteFooter/footer.scss";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          revisionVersion
        }
      }
    }
  `);

  const { productionUrl, brandFullName } = useThemeOption();

  return (
    <>
    <div className="mt-4">
      <Subscribe />
      <footer className="site-footer text-center wrapper" id="wrapper-footer">
        <FooterTop />
        <FooterSocialLinks />
        <Container>
          <div className="footer__bottom">
            © {new Date().getFullYear()}
            {` `}
            <a href={productionUrl}>{brandFullName}</a>{" "}
            <small>(Rev. {data.site.siteMetadata.revisionVersion})</small>
          </div>
        </Container>
      </footer>
      </div>
    </>
  );
};

export default Footer;
