import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { NavItem, Nav, Container } from "reactstrap";
import { Truck as TruckIcon } from "styled-icons/fa-solid/Truck";

import ProductCategoryDropdown from "./product-category-dropdown.js";
import MobileMenu from "./MobileMenu";
import useDevice from "./hooks";
import "./style.scss";
import { ShippingContainer, Navbar } from "./styles";

const BottomNav = () => {
  const device = useDevice();
  const [isMobile, setIsMobile] = useState(device);
  useEffect(() => {
    const checkIsMobile = "desktop" !== device;
    setIsMobile(checkIsMobile);

    return () => {};
  }, [device]);

  return (
    <Container>
      <Navbar dark className="bottom__nav px-0" expand="md">
        <div className="d-flex">
          <ul className="menu menu-bar">
            {isMobile ? (
              <MobileMenu />
            ) : (
              <ProductCategoryDropdown active="all" />
            )}

            <NavItem className="md-hide">
              <Link className="nav-link" to="/product-category/brands/">
                Brands
              </Link>
            </NavItem>
            <NavItem className="md-hide">
              <Link
                className="nav-link"
                to="/financing-for-sewing-machine-purchases/"
              >
                Financing
              </Link>
            </NavItem>
          </ul>
        </div>
        <ShippingContainer>
          <Nav style={{ fontStyle: "italic" }}>
            <NavItem>
              <Link className="nav-link" to="/shipping-policies/">
                <TruckIcon size="20" className="mr-2 align-middle" />
                Free Shipping $49
              </Link>
            </NavItem>
          </Nav>
        </ShippingContainer>
      </Navbar>
    </Container>
  );
};

export default BottomNav;
