import React from "react";
import { useThemeOption } from "@simplur/gatsby-theme-full-site/src/hooks";
import GoogleMapReact from "google-map-react";
import { graphql, useStaticQuery } from "gatsby";

const markerStyle = {
  border: "1px solid white",
  borderRadius: "50%",
  height: 30,
  width: 30,
  cursor: "pointer",
  zIndex: 10,
};

const Marker = ({ smallLogo }) => {
  return (
    <img
      style={markerStyle}
      src={smallLogo.sourceUrl}
      alt={smallLogo.altText}
      // alt={constants.BRAND_FULLNAME}
    />
  );
};

const Location = () => {
  const data = useStaticQuery(graphql`
    query LocationsQuery {
      wpgraphql {
        themeGeneralSettings {
          ACFLogos {
            smallLogo {
              altText
              sourceUrl
              srcSet
            }
          }
          ACFGeneralInfo {
            infoEmail
            generalMapText
            generalMapLatitude
            generalMapLongitude
          }
        }
        venues {
          nodes {
            title
            content
            address
            city
            state
            zip
            uri
            mapLink {
              mapLink
            }
            locationDetails {
              locationClassesLink {
                url
                title
                target
              }
              locationStoreHours
              locationMapLink
              locationLatitude
              locationLongitude
              locationShortName
              locationMapZoomLevel
            }
            venueId
            venueLinks {
              venueLinks
            }
          }
        }
      }
    }
  `);

  const {
    google: { mapApiKey },
  } = useThemeOption();

  const smallLogo = data?.wpgraphql?.themeGeneralSettings?.ACFLogos?.smallLogo;

  const defaultLat =
    data?.wpgraphql?.themeGeneralSettings?.ACFGeneralInfo?.generalMapLatitude;

  const defaultLng =
    data?.wpgraphql?.themeGeneralSettings?.ACFGeneralInfo?.generalMapLongitude;

  const loc = data?.wpgraphql?.venues?.nodes;

  const defaultProps = {
    center: {
      lat: defaultLat,
      lng: defaultLng,
    },
    zoom: 8,
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        minHeight: "300px",
      }}
      className={"shadow rounded"}
    >
      <GoogleMapReact
        bootstrapURLKeys={{
          key: mapApiKey,
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        {loc &&
          loc.length &&
          loc.map((l, i) => (
            <Marker
              key={i}
              lat={l.locationDetails.locationLatitude}
              lng={l.locationDetails.locationLongitude}
              smallLogo={smallLogo}
            />
          ))}
      </GoogleMapReact>
    </div>
  );
};

export default Location;
