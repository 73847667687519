import styled from "styled-components";
import { Navbar as NB } from "reactstrap";

export const ShippingContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Navbar = styled(NB)`
    background-color: #800000;
    font-size: 1.03rem;
    font-family: "DM Sans";
    font-weight: bold;
    /* text-transform: uppercase; */
    justify-content: space-between;
    @media (max-width: 768px) {
        background-color: rgb(56, 65, 64);
        .md-hide {
            display: none;
        }
    }
`;