/*
  Author: Mohamed Nisfan
  email: mohnisfan@gmail.com
  CreatedAt: 11 Feb 2021
*/

import React, { useState } from "react";
import { Link } from "gatsby";
import { ListGroup, ListGroupItem, NavItem } from "reactstrap";
import { useTrail, a } from "react-spring";
import {
  FaChevronRight,
  FaChevronDown,
  FaChevronUp,
  FaArrowLeft,
  FaBars
} from "react-icons/fa";

import useHardcodedCategories from "./useHardcodedCategories";

const menusItems = useHardcodedCategories;

function Trail({ open, children, props }) {
  const items = React.Children.toArray(children);
  const trail = useTrail(items.length, {
    config: { mass: 2, tension: 2000, friction: 100 },
    opacity: open ? 1 : 0,
    x: open ? 0 : 20,
    height: open ? "auto" : 0,
    from: { opacity: 0, x: 50, height: 0 }
  });

  return (
    <div className="trails-main" {...props}>
      <div>
        {trail.map(({ x, height, ...rest }, index) => (
          <a.div
            key={index}
            className="trails-text"
            style={{
              ...rest,
              transform: x.interpolate(x => `translate3d(0,${x}px,0)`)
            }}
          >
            <a.div style={{ height }}>{items[index]}</a.div>
          </a.div>
        ))}
      </div>
    </div>
  );
}

const Menu = ({ menu, showSubMenu, onClickParent }) => {
  const clickEvent = (menu.onClick && menu.onClick) || showSubMenu;
  let parentClick;
  if (onClickParent && menu.children && menu.children.length) {
    parentClick = onClickParent;
  }

  return (
    <ListGroupItem
      style={{ cursor: "pointer" }}
      onClick={() => (parentClick ? parentClick(menu) : clickEvent(menu))}
      className="mobile__menu-item"
    >
      <Link to={menu.uri}>
        <>
          {menu.name === "Back" ? (
            <span>
              <FaArrowLeft />
              &nbsp;
            </span>
          ) : (
            ""
          )}

          {menu.name}
        </>
      </Link>

      {menu.children && menu.children.length && (
        <span
          className="float-right"
          aria-label="icon"
          onClick={() => clickEvent(menu)}
          role="img"
        >
          <FaChevronRight />
        </span>
      )}
    </ListGroupItem>
  );
};

const MobileMenu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const [menus, setMenus] = useState(menusItems);
  const toggleMenu = e => {
    e.preventDefault();
    if (!showMenu) setMenus(menusItems);
    setShowMenu(p => !p);
  };

  const showSubMenu = menu => {
    if (menu.children) {
      const backButton = {
        name: "Back",
        slug: "back",
        onClick: () => {
          setMenus(menus);
        }
      };
      const children = [backButton, ...menu.children];
      setMenus(children);
    }
  };

  return (
    <NavItem className="mobile__menu-wrap">
      <a
        href="#"
        color="primary"
        className="menu-link menu-bar-link browse__category-link nav-link d-flex align-items-center"
        onClick={toggleMenu}
      >
        <FaBars className="mr-2" /> Browse Categories &nbsp;&nbsp;
        <span style={{ float: "right" }}>
          {showMenu ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </a>
      <ListGroup className={showMenu ? "menu__opened" : ""}>
        <Trail open={showMenu} onClick={() => setShowMenu(state => !state)}>
          {menus.map(m => {
            return <Menu key={m.uri} menu={m} showSubMenu={showSubMenu} />;
          })}
        </Trail>
      </ListGroup>
    </NavItem>
  );
};

export default MobileMenu;
