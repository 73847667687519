import React, { useState, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroupAddon,
} from "reactstrap";

import SubmitButton from "@simplur/gatsby-theme-full-site/src/components/SubmitButton";
import FormInput from "@simplur/gatsby-theme-full-site/src/components/FormInput";
import {
  FormContextProvider,
  FormContext,
} from "@simplur/gatsby-theme-full-site/src/contexts/FormContext";

import {
  useNoCodeApi,
  useThemeOption,
  useAlert,
} from "@simplur/gatsby-theme-full-site/src/hooks";

const SubscribeForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const { alertElement, showInfo, showDanger, hideAlert } = useAlert();
  const {
    state: { data: formData },
    actions,
  } = useContext(FormContext);

  const {
    noCodeApi: { RFPId },
  } = useThemeOption();

  const { handler } = useNoCodeApi(RFPId, "Subscribers");

  const onSubmitHandler = async (e) => {
    e.preventDefault();

    hideAlert();
    try {
      setSubmitting(true);
      showInfo("Processing your request...");

      const data = JSON.stringify([
        [formData.email, new Date().toLocaleString()],
      ]);
      const json = await handler(data);

      if (json.row_id) {
        setSubmitting(false);
        actions.reset();
        showInfo(
          "Thank you for subscribing. We'll let you know soon about our upcoming sales, events, and classes."
        );
      } else {
        if (typeof json.error === "object") throw Error(json.error?.message);
        if (json.code !== 200) throw Error(json.info);
      }
    } catch (e) {
      setSubmitting(false);
      console.error(e);
      showDanger(e.toString());
    }
  };

  return (
    <>
      {alertElement}
      <Form
        inline
        className="justify-content-center"
        onSubmit={onSubmitHandler}
        name="Subscribers"
      >
        <FormGroup>
          <Label className="mr-3">
            Get regular updates on classes, events, discounts and community
            news.
          </Label>
          <FormInput
            type="email"
            name="email"
            id="email"
            placeholder="Email Address"
            required
            showErrorLabel={false}
          />
          <InputGroupAddon addonType="append">
            <SubmitButton
              color="cta"
              submitting={submitting}
              className="sign-up__btn"
            >
              Sign Up Now
            </SubmitButton>
          </InputGroupAddon>
        </FormGroup>
        {/* <FormGroup check className="mb-3">
                    <FormInput
                        required
                        type="checkbox"
                        id="agree"
                        name="agree"
                        label={CheckboxLabel}
                    />
                </FormGroup> */}
      </Form>
    </>
  );
};

const Subscribe = () => {
  const initialState = { email: "", agree: false };

  return (
    <>
      <div className="subscribe-footer">
        <Container className="py-3">
          <>
            <Row>
              <Col className="text-center">
                <h3 style={{ color: "white", textTransform: "capitalize" }}>
                  Specials and Updates
                </h3>
              </Col>
            </Row>

            <Row>
              <Col className="text-white">
                <FormContextProvider initialFormState={initialState}>
                  <SubscribeForm />
                </FormContextProvider>
              </Col>
            </Row>
          </>
        </Container>
      </div>
    </>
  );
};

export default Subscribe;
