/**
 * Check if is given breakpoint.
 *
 * @param {string} minSize Minimum device size.
 * @param {string} maxSize Maximum device size.
 *
 * @return {boolean}
 */
const isBreakpoint = (minSize, maxSize) => {
  /**
   * Size are same as used in src/sass/0-settings/_foundation.scss
   */
  const sizes = {
    small: 0,
    medium: 768,
    large: 1024,
    xlarge: 1280
  };

  if (
    "undefined" === typeof window ||
    "undefined" === sizes[minSize] ||
    "undefined" === sizes[maxSize]
  ) {
    return false;
  }

  return (
    window.innerWidth > sizes[minSize] && window.innerWidth < sizes[maxSize]
  );
};

/**
 * Is small breakpoint.
 *
 * @return {boolean}
 */
const isBreakpointSmall = () => isBreakpoint("small", "medium");

/**
 * Is medium breakpoint.
 *
 * @return {boolean}
 */
const isBreakpointMedium = () => isBreakpoint("small", "large");

/**
 * Get breakpoint.
 *
 * @return {string}
 */
export const getDevice = () => {
  let device = "desktop";

  if (isBreakpointSmall()) {
    device = "mobile";
  } else if (isBreakpointMedium()) {
    device = "tablet";
  }

  return device;
};
