const categories = [
    {
        name: "Sale Items",
        uri: "/product-category/sale/",
    },
    {
        name: "All Machines",
        uri: "/product-category/sewing-machines/",
        children: [
            {
                name: "Sewing Machines",
                uri: "/product-category/sewing-machines/basic-sewing-machines/",
            },
            {
                name: "Serger & Coverstitch machines",
                uri: "/product-category/sewing-machines/sergers/",
            },
            {
                name: "Sewing & Quilting Combo Machines",
                uri: "/product-category/sewing-machines/sewing-quilting-machines-1/",
            },
            {
                name: "Sewing & Embroidery Combo Machines",
                uri: "/product-category/sewing-machines/sewing-embroidery-machines/",
            },
            {
                name: "Multi-Needle Embroidery",
                uri: "/product-category/sewing-machines/multi-needle-machines/",
                children: { edges: [] },
            },
            {
                name: "Embroidery Only Machines",
                uri: "/product-category/sewing-machines/embroidery-only-machines/",
                children: { edges: [] },
            },
            {
                name: "Longarm Quilting Machines",
                uri: "/product-category/sewing-machines/longarm-quilting-machines/",
                children: { edges: [] },
            },
            {
                name: "ScanNCut Machines",
                uri:
                    "/product-category/sewing-machines/brother-scanncut-machines-brother-scanncut-machines/",
            },
        ],
    },
    {
        name: "Tools",
        uri: "/product-category/tools/",
        children: [
            {
                name: "Craft Tools",
                uri: "/product-category/tools/craft-tools/",
            },
            {
                name: "Irons & Accessories",
                uri: "/product-category/tools/irons-and-steamers/",
            },
            {
                name: "Lights & Magnifiers",
                uri: "/product-category/tools/lights-magnifiers/",
            },
            {
                name: "Cutting Tools",
                uri: "/product-category/tools/cutting-tools-tools/",
            },
            {
                name: "Cutting Mats",
                uri: "/product-category/tools/cutting-mats-tools/",
            },
            {
                name: "Rulers & Templates",
                uri: "/product-category/tools/rulers-for-quilting-sewing-and-more/",
            },
        ],
    },
    {
        name: "Sewing Supplies",
        uri: "/product-category/sewing-supplies/",
        children: [
            {
                name: "General Sewing Notions",
                uri: "/product-category/sewing-supplies/sewing-notions/",
            },
            {
                name: "Bobbins",
                uri: "/product-category/sewing-supplies/bobbins-bobbin-storage/",
            },
            {
                name: "Books",
                uri: "/product-category/sewing-supplies/sewing-quilting-books/",
            },
            {
                name: "Pins & Needles",
                uri: "/product-category/sewing-supplies/pins-needles/",
            },
            {
                name: "Extension Tables",
                uri: "/product-category/sewing-supplies/extension-tables/",
            },
        ],
    },
    {
        name: "Embroidery Supplies",
        uri: "/product-category/embroidery-supplies/",
        children: [
            {
                name: "General Embroidery Notions",
                uri: "/product-category/embroidery-supplies/embroidery-accessories/",
            },
            {
                name: "Hoops",
                uri: "/product-category/embroidery-supplies/hoops/",
            },
            {
                name: "Stabilizers",
                uri:
                    "/product-category/embroidery-supplies/stabilizers-embroidery-supplies/",
            },
            ,
            {
                name: "Embroidery Thread",
                uri: "/product-category/embroidery-supplies/embroidery-thread/",
            },
            {
                name: "Embroidery Designs",
                uri: "/product-category/embroidery-supplies/embroidery-designs/",
            },
            {
                name: "Pins & Needles",
                uri:
                    "/product-category/embroidery-supplies/pins-needles-embroidery-supplies/",
            },
        ],
    },
    {
        name: "Quilting Supplies",
        uri: "/product-category/quilting-supplies/",
        children: [
            {
                name: "Quilting Notions",
                uri:
                    "/product-category/quilting-supplies/quilting-notions/",
            },
            {
                name: "Batting",
                uri:
                    "/product-category/quilting-supplies/batting-for-quilting-embroidery-and-more/",
            },
            {
                name: "Rotary Cutters",
                uri: "/product-category/quilting-supplies/rotary-cutters/",
            },
            {
                name: "Quilting Frames",
                uri: "/product-category/quilting-supplies/quilting-frames/",
            },
            {
                name: "Cutting Mats",
                uri: "/product-category/quilting-supplies/cutting-mats/",
            },
            {
                name: "Machine Quilting Rulers & Templates",
                uri:
                    "/product-category/quilting-supplies/machine-quilting-rulers-templates/",
            },
            {
                name: "Pins & Needles",
                uri: "/product-category/quilting-supplies/pins-needles-quilting-tools/",
            },
            {
                name: "Extension Tables",
                uri:
                    "/product-category/quilting-supplies/extension-tables-quilting-tools/",
            },
        ],
    },
    {
        name: "Hoops & Stabilizers",
        uri: "/product-category/hoops-stabilizers/",
    },
    {
        name: "Presser Feet",
        uri: "/product-category/sewing-quilting-feet/",
    },
    {
        name: "Machine Software",
        uri: "/product-category/machine-software/",
    },
    {
        name: "Sewing Furniture & Storage",
        uri: "/product-category/sewing-furniture/",
        children: [
            {
                name: "Sewing Cabinets",
                uri: "/product-category/sewing-furniture/sewing-cabinets/",
                children: { edges: [] },
            },
            {
                name: "Cutting & Craft Tables",
                uri: "/product-category/sewing-furniture/cutting-craft-tables/",
                children: { edges: [] },
            },
            {
                name: "Sewing Chairs",
                uri: "/product-category/sewing-furniture/sewing-chairs/",
                children: { edges: [] },
            },
            {
                name: "Lighting Products",
                uri: "/product-category/sewing-furniture/lighting-products/",
                children: { edges: [] },
            },
            {
                name: "Notions Storage",
                uri: "/product-category/sewing-furniture/storage/",
                children: { edges: [] },
            },
        ],
    },
    {
        name: "Vacuum Cleaners",
        uri: "/product-category/vacuum-cleaners/",
        children: [
            {
                name: "Vacuums",
                uri: "/product-category/vacuum-cleaners/canister-vacuums",
                children: { edges: [] },
            },
            {
                name: "Vacuum Accessories",
                uri: "/product-category/vacuum-cleaners/vacuum-accessories",
                children: { edges: [] },
            },
        ],
    },
];
const useHardcodedCategories = () => {
    return categories;
};
export default useHardcodedCategories;
