import React, { useState } from "react";
import Search from "@simplur/gatsby-theme-full-site/src/components/search";
import { Search as SearchIcon } from "styled-icons/fa-solid/Search";
import { Button } from "reactstrap";
import { Container } from "./styles";

const searchIndices = [
  {
    name: process.env.GATSBY_ALGOLIA_SEARCH_POST_PAGE_INDEX,
    title: `Search Results`,
    hitComp: `PostHit`
  },
  {
    name: process.env.GATSBY_ALGOLIA_SEARCH_PRODUCT_CAT_INDEX,
    title: `Search Results`,
    hitComp: `TaxHit`
  }
];

const SearchForm = () => {
  const [showSearchField, toggleShowSearchField] = useState(false);

  return (
    <Container className="search__form d-flex flex-grow-1 align-items-center ml-md-4 ml-lg-6">
      <Search indices={searchIndices} />
      <Button
        className="search__icon"
        color="primary"
        onClick={() => {
          toggleShowSearchField(!showSearchField);
        }}
      >
        <SearchIcon size="16" />
      </Button>
    </Container>
  );
};

export default SearchForm;
