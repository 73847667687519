import React, { useState } from "react";
import { NavItem } from "reactstrap";
import useHardcodedCategories from "./useHardcodedCategories";
import { FaChevronDown, FaChevronUp, FaBars } from "react-icons/fa";

import MenuItem from "./MenuItem";

const ProductCategoryDropdown = () => {
  const productCategories = useHardcodedCategories();

  const [showMainMenu, setShowMainMenu] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(null);

  const setTheSubMenu = (catIndex) => {
    if (showSubMenu === catIndex) {
      setShowSubMenu(null);
    } else {
      setShowSubMenu(catIndex);
    }
  };

  return (
    <NavItem>
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          setShowMainMenu(!showMainMenu);
        }}
        style={{ backgroundColor: "#384140" }}
        className="menu-link menu-bar-link browse__category-link d-flex align-items-center"
        aria-haspopup="true"
      >
        <FaBars className="mr-2" /> Browse Categories&nbsp;&nbsp;
        <span style={{ float: "right" }}>
          {showMainMenu ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </a>

      <ul
        className="mega-menu mega-menu--multiLevel"
        style={{ display: showMainMenu ? "block" : "none" }}
      >
        {productCategories.map((item, catIndex) => (
          <MenuItem
            {...item}
            key={item.name}
            catIndex={`parent_index_${catIndex}`}
            setTheSubMenu={setTheSubMenu}
            showSubMenu={showSubMenu}
          />
        ))}
      </ul>
    </NavItem>
  );
};

export default ProductCategoryDropdown;
