/**
 * External dependencies.
 */
import { useEffect, useState } from "react";
import debounce from "lodash.debounce";

/**
 * Internal dependencies.
 */
import { getDevice } from "./functions";

/**
 * useDevice hook.
 *
 * @return {string} device
 */
const useDevice = () => {
  const [device, setDevice] = useState(getDevice());

  const onResize = debounce(() => setDevice(getDevice()), 100);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []); // eslint-disable-line

  return device;
};

export default useDevice;
