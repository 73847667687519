import React from "react";
import { Link } from "gatsby";
import { NavItem, Nav } from "reactstrap";
import SignInNavItem from "./NavItems/SignInNavItem";

const Menu = () => {
  return (
    <div className="ml-md-auto">
      <Nav className="justify-content-center py-0">
        <NavItem>
          <Link className="nav-link d-none d-lg-block" to="/locations/">
            Locations
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link d-none d-lg-block" to="/sewing-classes/">
            Events & Classes
          </Link>
        </NavItem>
        <NavItem>
          <Link className="nav-link d-none d-lg-block" to="/contact-us/">
            Contact Us
          </Link>
        </NavItem>
        <SignInNavItem
          buttonOutline={false}
          className="d-none d-lg-block"
          color=""
        />
      </Nav>
    </div>
  );
};

export default Menu;
