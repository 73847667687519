import React from "react";
import { Container, Row, Col } from "reactstrap";
import Location from "../Location";
import FooterTopLink from "./FooterTopLink";

const locations = {
  title: "Locations",
  links: [
    {
      slug: "/location/brea/",
      label: "Brea"
    },
    {
      slug: "/location/corona-2",
      label: "Corona"
    },
    {
      slug: "/location/huntington-beach",
      label: "Huntington Beach"
    },
    {
      slug: "/location/mission-viejo",
      label: "Mission Vijeo"
    },
    {
      slug: "/location/temecula-3",
      label: "Temecula"
    }
  ]
};

const service = {
  title: "Service",
  links: [
    {
      slug: "/contact-us",
      label: "Contact us"
    },
    {
      slug: "/local-service-repair",
      label: "Repair and service"
    },
    {
      slug: "/adopt-a-customer",
      label: "Adopt-a-customer Program"
    }
  ]
};

const about = {
  title: "About",
  links: [
    {
      slug: "/about-moores",
      label: "About Moore's"
    },
    {
      slug: "/sewing-blog",
      label: "Sewing Blog"
    },
    {
      slug: "/terms-conditions",
      label: "Class Policies"
    },
    {
      slug: "/faq",
      label: "FAQ"
    },
    {
      slug: "/terms-conditions",
      label: "Terms and Condition"
    },
    {
      slug: "/privacy-policy",
      label: "Privacy Policy"
    }
  ]
};

const resources = {
  title: "Resources",
  links: [
    {
      slug: "/sewing-classes",
      label: "Events"
    },
    {
      slug: "/sewing-classes",
      label: "Classes"
    },
    {
      slug: "/financing-for-sewing-machine-purchases",
      label: "Financing"
    },
    {
      slug: "/careers",
      label: "Careers"
    }
  ]
};

const FooterTop = () => {
  return (
    <Container>
      <Row className="gutter__80">
        <Col lg="6">
          <Row>
            <Col lg="7" className="mb-3">
              <Location />
            </Col>
            <Col lg="5" className="has__separator">
              <FooterTopLink title={locations.title} links={locations.links} />
              <div className="column__separator"></div>
            </Col>
          </Row>
        </Col>
        <Col lg="6">
          <Row>
            <Col lg="4">
              <FooterTopLink title={service.title} links={service.links} />
            </Col>
            <Col lg="4">
              <FooterTopLink title={about.title} links={about.links} />
            </Col>
            <Col lg="4">
              <FooterTopLink title={resources.title} links={resources.links} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterTop;
