import React from "react";
import { Link } from "gatsby";
import { Badge } from "reactstrap";
// import styled from "styled-components";

import { useCart } from "@simplur/gatsby-theme-full-site/src/hooks";

// const CartCta = styled(ShoppingCart)`
//   width: 1rem;
// `;
// const CartWhite = styled(ShoppingCart)`
//   color: #fff;
//   width: 1rem;
// `;

const CartNavItem = () => {
  const {
    state: { items: bucket },
  } = useCart();

  if (typeof window === "undefined") {
    return <></>;
  }

  const { AnimateOnChange } = require("react-animation");

  return (
    <AnimateOnChange
      animationIn="bounceIn"
      animationOut="bounceOut"
      durationOut={500}
    >
      {bucket.length === 0 ? (
        <Link
          to="/cart"
          title="Cart"
          // className="mr-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            height="24"
            width="24"
            viewBox="0 0 16 16"
            fill="#384140"
          >
            <path
              className="st0"
              d="M2.8,9.8h11c0.2,0,0.5-0.2,0.5-0.5L16,2.6c0.1-0.3-0.1-0.7-0.4-0.8c0,0-0.1,0-0.1,0H2.7L2.5,0.5
	C2.4,0.2,2.2,0,1.9,0H0.6C0.2,0,0,0.3,0,0.6s0.2,0.6,0.6,0.6h0.9l1.2,7.4c0,1.6,0.1,3.2,0.1,4.3h0.5c-0.5,1.1,0,2.4,0.9,2.9
	c1,0.5,2.1,0.1,2.6-1c0.3-0.6,0.3-1.3,0-1.9h2.9c-0.5,1.1,0,2.4,0.9,2.9c1,0.5,2.1,0.1,2.6-1c0.3-0.6,0.3-1.3,0-1.9h1.6
	c0.3,0,0.6-0.3,0.6-0.6s-0.2-0.6-0.6-0.6H2.8V9.8z M14.7,3.1l-1.4,5.5H3.8L2.9,3.1H14.7z M5.8,13.8c0,0.5-0.4,0.9-0.8,0.9
	s-0.8-0.4-0.8-0.9c0-0.5,0.4-0.9,0.8-0.9S5.8,13.3,5.8,13.8z M12.1,13.8c0,0.5-0.4,0.9-0.8,0.9c-0.5,0-0.8-0.4-0.8-0.9
	c0-0.5,0.4-0.9,0.8-0.9C11.8,12.9,12.1,13.3,12.1,13.8z"
            />
            <polygon
              fill="384140"
              points="2.7,8.6 2.7,12.9 3.7,12.9 3.8,8.6 3,8.9 "
            />
          </svg>
        </Link>
      ) : (
        <Link color="link" to="/cart" title="Cart" className="p-0">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            height="24"
            width="24"
            viewBox="0 0 16 16"
            fill="#384140"
          >
            <path
              className="st0"
              d="M2.8,9.8h11c0.2,0,0.5-0.2,0.5-0.5L16,2.6c0.1-0.3-0.1-0.7-0.4-0.8c0,0-0.1,0-0.1,0H2.7L2.5,0.5
            C2.4,0.2,2.2,0,1.9,0H0.6C0.2,0,0,0.3,0,0.6s0.2,0.6,0.6,0.6h0.9l1.2,7.4c0,1.6,0.1,3.2,0.1,4.3h0.5c-0.5,1.1,0,2.4,0.9,2.9
            c1,0.5,2.1,0.1,2.6-1c0.3-0.6,0.3-1.3,0-1.9h2.9c-0.5,1.1,0,2.4,0.9,2.9c1,0.5,2.1,0.1,2.6-1c0.3-0.6,0.3-1.3,0-1.9h1.6
            c0.3,0,0.6-0.3,0.6-0.6s-0.2-0.6-0.6-0.6H2.8V9.8z M14.7,3.1l-1.4,5.5H3.8L2.9,3.1H14.7z M5.8,13.8c0,0.5-0.4,0.9-0.8,0.9
            s-0.8-0.4-0.8-0.9c0-0.5,0.4-0.9,0.8-0.9S5.8,13.3,5.8,13.8z M12.1,13.8c0,0.5-0.4,0.9-0.8,0.9c-0.5,0-0.8-0.4-0.8-0.9
            c0-0.5,0.4-0.9,0.8-0.9C11.8,12.9,12.1,13.3,12.1,13.8z"
            />
            <polygon
              fill="384140"
              points="2.7,8.6 2.7,12.9 3.7,12.9 3.8,8.6 3,8.9 "
            />
          </svg>
          <Badge
            color="primary"
            className="align-top"
            style={{ marginLeft: "-..25rem", borderRadius: "50%" }}
          >
            {bucket.length}
          </Badge>
        </Link>
      )}
    </AnimateOnChange>
  );
};

export default CartNavItem;
